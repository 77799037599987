























































































































import { Component, Prop, Vue, Emit } from 'vue-property-decorator'
import { required, email, minLength, maxLength } from 'vuelidate/lib/validators'
import { UserModel } from '@/core/models/UserModel'
import { namespace } from 'vuex-class'
const UserStore = namespace('UserStore')

import BaseButton from '@/components/base/BaseButton.vue'
import FormGroup from '@/components/forms/FormGroup.vue'
import Field from '@/components/forms/Field.vue'

const validations = {
  userModel: {
    requisites: {
      rs: { required, minLength: minLength(20), maxLength: maxLength(20) },
      name_full: { required },
      ceo_imp: { required },
      inn: { required },
      kpp: { maxLength: maxLength(9) },
      bik: { required },
    },
  },
}

@Component({
  name: 'ProfileEditForm',
  components: { FormGroup, Field, BaseButton },
  validations,
})
export default class extends Vue {
  @Prop({ required: true })
  userModel!: UserModel

  @Emit('update-profile')
  successUpdateProfile(): boolean {
    return true
  }

  get rsValidationText(): string | null {
    const rs = (this as any).$v.userModel.requisites.rs
    const isDirty = rs.$dirty

    if (isDirty && !rs.required) {
      return 'Поле не заполнено'
    } else if (isDirty && (!rs.minLength || !rs.maxLength)) {
      return 'Длина Р/С должна быть 20 символов'
    }

    return null
  }

  @UserStore.Action
  private updateUser!: (user: UserModel) => Promise<UserModel | any>

  async saveProfile(): Promise<void | boolean> {
    this.$v.$touch()

    if (this.$v.$invalid) {
      return false
    } else {
      const requisites = this.userModel.requisites

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      await this.updateUser(requisites)

      this.successUpdateProfile()
    }
  }
}
